import React from "react";
import { GoBack, GoBackWrapper, StyledArrowWrapper, StyledTitle, StyledSubtitle } from "./style";
import LeftArrow from "../../../images/icons/left-arrow.svg";
import RectangleCard from "../../common/rectangle-card";
import MyPOSIcon from "../../../images/icons/my-pos.svg";
import RequestPOSIcon from "../../../images/icons/request-pos.svg";
import { Box } from "rebass/styled-components";

import CardOne from "../../common/cards/card-one";

const posData = [
  {
    icon: <MyPOSIcon />,
    title: "My POS",
    subtitle: "Activities about your POS",
    link: "#",
  },
  {
    icon: <RequestPOSIcon />,
    title: "Request POS",
    subtitle: "Get an OPay POS",
    link: "#",
  },
];

const cardData = [
  {
    title: "Other information",
    text1: "OPay Office",
    text1link: "#",
    text2: "FAQ",
    text2link: "#",
  },
];

const IndexPage = ({ title, subtitle }) => {
  return (
    <Box maxWidth="38rem" mx="auto">
      <GoBackWrapper>
        <GoBack>
          <StyledArrowWrapper>
            <LeftArrow />
          </StyledArrowWrapper>
        </GoBack>
      </GoBackWrapper>

      <StyledTitle>{title}</StyledTitle>
      <StyledSubtitle>{subtitle}</StyledSubtitle>

      {posData.map((data, index) => (
        <RectangleCard {...data} key={index} />
      ))}

      {cardData.map((card, index) => (
        <CardOne {...card} key={index} />
      ))}
    </Box>
  );
};

export default IndexPage;
