import React from "react";
import styled from "styled-components";
import RightArrow from "../../images/icons/right-arrow.svg";

const RectangleCard = ({ icon, title, subtitle, link }) => {
  return (
    <StyledWrapper>
      <StyledLink href={link}>
        <StyledRectangle>
          <StyledContentWrapper>
            <StyledMainIcon>{icon}</StyledMainIcon>
            <StyledText>
              <StyledTitle>{title}</StyledTitle>
              <StyledSubtitle>{subtitle}</StyledSubtitle>
            </StyledText>
            <StyledArrowIcon>
              <RightArrow />
            </StyledArrowIcon>
          </StyledContentWrapper>
        </StyledRectangle>
      </StyledLink>
    </StyledWrapper>
  );
};

export default RectangleCard;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledRectangle = styled.div`
  width: 320px;
  height: 60px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white.regular};
  margin-bottom: 20px;
`;

const StyledContentWrapper = styled.div`
  padding: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledMainIcon = styled.div`
  max-width: 45px;
  padding: 4px 10px 0 15px;
`;

const StyledText = styled.div`
  width: 170px;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 30px;
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.mypos.title};
`;

const StyledSubtitle = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.mypos.subtitle};
`;

const StyledArrowIcon = styled.div`
  margin: 0 0 0 70px;
`;
