import styled from "styled-components";
// import { Button } from "rebass/styled-components";

export const GoBackWrapper = styled.div`
  padding: 50px 30px 30px 30px;
`;

export const GoBack = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.mypos.lightPurple};
  padding: 15px;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mq.lg`
    `}
`;

export const StyledArrowWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.h3`
  font-size: 24px;
  padding-left: 30px;
  margin-top: 10px;
`;

export const StyledSubtitle = styled.p`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.mypos.subtitle};
  margin-top: 20px;
  padding: 0 0 40px 30px;
`;
