import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import MyPOSPage from "../components/sections/my-pos";

const MyPOS = () => {
  return (
    <Layout>
      <SEO title="My POS" />
      <MyPOSPage title="POS" subtitle="Check activities and request for POS." />
    </Layout>
  );
};

export default MyPOS;
