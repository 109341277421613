import React from "react";
import styled from "styled-components";

const CardOne = ({ title, text1, text1link, text2, text2link }) => {
  return (
    <StyledWrapper>
      <StyledCard>
        <StyledTitle>{title}</StyledTitle>
        <StyledHorizontalLine />
        <StyledContentWrapper>
          <StyledText>
            <a href={text1link}>{text1}</a>
          </StyledText>
          <StyledText>
            <a href={text2link}>{text2}</a>
          </StyledText>
        </StyledContentWrapper>
      </StyledCard>
    </StyledWrapper>
  );
};

export default CardOne;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 10px;
`;

const StyledCard = styled.div`
  width: 320px;
  height: 163px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white.regular};
  margin-bottom: 20px;
`;

const StyledTitle = styled.h3`
  font-size: ${props => props.theme.fontSizes.sm};
  display: flex;
  justify-content: flex-start;
  padding: 30px 0 15px 15px;
`;

const StyledHorizontalLine = styled.hr`
  border-top: ${props => props.theme.colors.mypos.horizontal};
  width: 90%;
`;

const StyledContentWrapper = styled.div`
  color: ${props => props.theme.colors.mypos.purple};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledText = styled.div`
  padding: 10px 0 10px 15px;

  a {
    text-decoration: none;
  }
`;
